<template>
  <div class="wrap">
    <!-- <van-nav-bar
      title="双向转诊"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->




     <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 24px;"
                @click="onClickLeft"   v-show="!backIcon" />
            <span style="font-size: 20px;color:#323233; font-weight: bold;">
              双向转诊
            </span>
        </div>





    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('@/assets/images/swiper.png')"
          alt=""
          style="width: 100%; height: 100%"
      /></van-swipe-item>
    </van-swipe>
    <div class="box">
      <van-grid :column-num="2" gutter="20px" icon-size="48px">
        <van-grid-item
          :icon="require('@/assets/images/icon_outHospital.png')"
          text="本院转出"
          to="/outHospital"
        />
        <van-grid-item
          :icon="require('@/assets/images/icon_inHospital.png')"
          text="本院转入"
          to="/inHospital"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       backIcon:localStorage.getItem("routerHistory"),
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.wrap{
padding-top: 20px;
}
  .nav_bar {
        text-align: center;
        color: #323233;
        padding-bottom: 20px;
    }
.box {
  margin-top: 50px;
}
 /deep/.van-grid-item__text {
   font-size: 16px;
  }
</style>
